<script setup>
import {Head} from '@inertiajs/vue3'
import SubscribersLayout from "../../../Layouts/SubscribersLayout.vue";
import MagicLinkForm from "../../../../_Components/MagicLinkForm.vue";
import { themes } from '../../../../_Themes/themes';

const props = defineProps({
    companyId: String,
});

const themeSelected = themes('lfx');
</script>

<template>
    <Head title="Inicia sesión"/>

    <SubscribersLayout :themeSelected="themeSelected">
        <!-- <h1 class="text-lfx-brown uppercase text-4xl font-medium text-center">Administra tu membresía</h1>
        <p class="text-xl text-lfx-black mt-10 text-center max-w-5xl px-10 mx-auto">
            A través de este portal podrás ingresar tus datos de facturación y solicitar tu factura.
            También podrás consultar tu historial de pagos y actualizar tu método de pago.
        </p>
        <p class="text-xl text-lfx-black mt-5 text-center max-w-5xl px-10 mx-auto">
            Para obtener tu link de acceso escribe tu correo electrónico asociado a tu membresía.
        </p> -->
        <h1 class="text-lfx-brown uppercase text-4xl font-medium text-center">Solicita tu factura</h1>
        <ol type="1" class="text-xl text-lfx-black mt-10 text-left max-w-[560px] px-5 mx-auto space-y-1">
            <li><span class="text-lfx-brown font-bold mr-1">1.</span>Escribe tu correo electrónico (asociado a tu membresía).</li>
            <li><span class="text-lfx-brown font-bold mr-1">2.</span>Te llegará un link por correo para darte acceso al portal.</li>
            <li><span class="text-lfx-brown font-bold mr-1">3.</span>Ingresa al portal y llena tu perfil de facturación.</li>
            <li><span class="text-lfx-brown font-bold mr-1">4.</span>Revisa el historial de pagos y genera tu factura.</li>
        </ol>
        <div class="mt-16 max-w-[400px] mx-auto">
            <MagicLinkForm
                :company="companyId"
                classes-input="ring-lfx-brown-light focus:ring-[#F1D2CC] rounded-md"
                classes-button="bg-lfx-brown text-white rounded-md"
            >
                Obtener link de acceso
            </MagicLinkForm>
        </div>
    </SubscribersLayout>
</template>

